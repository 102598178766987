import React, { FC } from 'react';
import { useLocation } from 'react-router-dom';

const FacebookLikePlugin: FC = () => {
  const { pathname } = useLocation();

  const hidePlugin = pathname.startsWith('/kereses') || pathname.startsWith('/rolunk');

  return (
    <div
      className="fb-page hide-on-mobile"
      data-href="https://www.facebook.com/Hazait-A-H%C3%A1zhoz-108981174133800/"
      data-tabs="timeline"
      data-width="280"
      data-height="150"
      data-small-header="true"
      data-adapt-container-width="false"
      data-hide-cover="false"
      data-show-facepile="true"
      style={hidePlugin ? { display: 'none' } : {}}
    >
      <blockquote cite="https://www.facebook.com/Hazait-A-H%C3%A1zhoz-108981174133800/" className="fb-xfbml-parse-ignore">
        <a href="https://www.facebook.com/Hazait-A-H%C3%A1zhoz-108981174133800/">Hazait A Házhoz</a>
      </blockquote>
    </div>
  );
};

export default FacebookLikePlugin;
