const resultpageUrl = '/kereses/';
const marketplaceName = 'Hazaitól a Házhoz Piactér';
const shoprenterUrl = 'https://www.shoprenter.hu/';
const rapidSearchUrl = 'https://www.rapidsearch.hu/';
const typeFormUrl = 'https://form.typeform.com/to/vLBg0J';
const huProductsString = 'hu_products';
const huProductsDescription =
  'A terméket olyan webáruház árulja, ahol többségében magyar vagy hazai feldolgozású termékeket értékesítenek.';
const size = Math.max(Math.ceil((window.innerWidth - 320) / 250) * Math.ceil((window.innerHeight - 140) / 385), 2);

export {
  huProductsDescription,
  huProductsString,
  rapidSearchUrl,
  resultpageUrl,
  marketplaceName,
  size,
  shoprenterUrl,
  typeFormUrl,
};
