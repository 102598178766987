import React, { FC } from 'react';
import { typeFormUrl } from '../../constants';

const ApplyButton: FC = ({ children }) => (
  <a
    target="_blank"
    rel="noreferrer noopener"
    href={typeFormUrl}
    className="padding-horizontal-25px rs-orange-background border-radius-50px text-align-center"
  >
    <p className="padding-vertical-13px font-size-15px color-white font-weight-700">{children}</p>
  </a>
);

export default ApplyButton;
