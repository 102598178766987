import env from 'env-var';
import dotenv from 'dotenv';
import path from 'path';

// this is the only place where use of process.env is allowed ;)
export const isDev = process.env.NODE_ENV === 'development';

const envPath = isDev ? './env.development.local' : '.env';
dotenv.config({
  path: path.join(__dirname, `../${envPath}`),
  override: true,
});

export const config = {
  nodeEnv: env.get('NODE_ENV').asString(),
  elasticSearch: {
    url: env.get('REACT_APP_ES_URL').required().asString(),
    index: env.get('REACT_APP_ES_INDEX').required().asString(),
  },
  local: {
    sslKey: env.get('LOCAL_SSL_KEY').default('../.certs/app.key').required(isDev).asString(),
    sslCert: env.get('LOCAL_SSL_CERT').default('../.certs/app.crt').required(isDev).asString(),
  },
};
