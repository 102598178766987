import React, { FC, lazy, useEffect, Suspense } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { view } from '@risingstack/react-easy-state';
import { fetchEnabledStores } from './utils/functions';
import { marketplaceState } from './store';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import FacebookLikePlugin from './components/FacebookLikePlugin/FacebookLikePlugin';

async function fetchData(): Promise<void> {
  fetchEnabledStores().then((marketplaceStoreReturn) => {
    const inStockStores: Record<string, boolean> = {};
    const normalStores: Record<string, boolean> = {};

    const { stores, productCount, storeCount } = marketplaceStoreReturn;
    Object.keys(stores).forEach((storeId) => {
      if (stores[storeId].in_stock_only) {
        inStockStores[storeId] = stores[storeId].hu_products;
      } else {
        normalStores[storeId] = stores[storeId].hu_products;
      }
    });

    marketplaceState.stores = stores;
    marketplaceState.productCount = productCount;
    marketplaceState.storeCount = storeCount;
    marketplaceState.inStockStores = inStockStores;
    marketplaceState.normalStores = normalStores;
  });
}

const MarketPlace: FC = () => {
  useEffect(() => {
    fetchData();
  }, []);

  const FAQs = lazy(() => import('./pages/FAQs/FAQs'));
  const Home = lazy(() => import('./pages/Home/Home'));
  const About = lazy(() => import('./pages/About/About'));
  const SearchResults = lazy(() => import('./pages/SearchResults/SearchResults'));

  return (
    <Router>
      <Header />
      <div className="display-flex flex-1 flex-column">
        <Suspense fallback="">
          <Switch>
            <Route path="/kapcsolat">
              <Redirect to="/rolunk" />
            </Route>
            <Route exact path="/" component={Home} />
            <Route path="/kereses/:keyword" component={SearchResults} />
            <Route path="/kereses" component={SearchResults} />
            <Route exact path="/rolunk" component={About} />
            <Route exact path="/webshopoknak" component={FAQs} />
          </Switch>
        </Suspense>
      </div>
      <Footer />
      <FacebookLikePlugin />
    </Router>
  );
};

export default view(MarketPlace);
