import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';

type Props = {
  href?: string;
  to?: string;
  classNames?: string;
  children: React.ReactNode;
} & ({ href: string } | { to: string });

const Link: FC<Props> = ({ href, to, classNames = '', children }) => {
  const { push } = useHistory();

  const onClick = (): void => {
    if (href) {
      window.open(href, '_blank');

      return;
    }
    if (to) {
      push(to);
    }
  };

  return (
    <button
      type="button"
      onClick={onClick}
      style={{ fontSize: 'inherit', fontWeight: 700 }}
      className={`${classNames} font-weight-600 rs-orange-color cursor-pointer outline-none border-none background-none underline-on-hover`}
    >
      {children}
    </button>
  );
};

export default Link;
