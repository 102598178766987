import React, { FC } from 'react';
import { useLocation } from 'react-router-dom';
import Link from '../Link/Link';
import { rapidSearchUrl, shoprenterUrl } from '../../constants';
import { marketplaceState } from '../../store';
import './Footer.css';

const Footer: FC = () => {
  const { pathname } = useLocation();

  if (pathname.startsWith('/kereses')) return null;

  const spanClassname = marketplaceState.isMobile ? 'footer_span_mobile' : 'footer_span_desktop';

  return (
    <footer className="rs-maroon-background display-flex flex-column justify-content-end text-align-center padding-20px">
      <p style={{ color: 'rgb(230, 230, 230)' }} className="text-align-center">
        <span className={spanClassname}>
          A kereső motort a{' '}
          <Link classNames="footer-link-color font-weight-normal" href={rapidSearchUrl}>
            &copy; Rapid Search
          </Link>{' '}
          biztosította a&nbsp;
          <Link classNames="footer-link-color font-weight-normal" href={shoprenterUrl}>
            Shoprenter
          </Link>{' '}
          támogatásával. Minden jog fenntartva.
        </span>
        <span className={spanClassname}>
          <Link classNames="footer-link-color font-weight-normal" to="/rolunk">
            A kezdeményezésről
          </Link>{' '}
        </span>
        <span className={spanClassname}>
          <Link classNames="footer-link-color font-weight-normal" to="/webshopoknak">
            Webshopoknak
          </Link>
        </span>
      </p>
    </footer>
  );
};

export default Footer;
