import { store } from '@risingstack/react-easy-state';
import { MarketPlaceState } from './entities/types';
import { parseAggregations } from './utils/functions';
import { resultpageUrl } from './constants';

const currentUrl = decodeURI(window.location.pathname);
const isResultPage = currentUrl.includes(resultpageUrl);
const keyword = isResultPage ? currentUrl.replace(resultpageUrl, '') : '';

const defaultMarketplaceState: MarketPlaceState = store({
  keyword,
  sortBy: {
    label: 'Relevancia',
    field: '_score',
    order: 'desc',
  },
  filters: {
    availability: [],
    price: {
      min: 0,
      max: 0,
    },
    manufacturer: [],
  },
  stores: {},
  productCount: 0,
  storeCount: 0,
  inStockStores: {},
  normalStores: {},
  aggregations: {
    manufacturers: [],
  },
  resultsLoading: false,
  setAggregations: ({ rawData }: any) => {
    if (rawData.aggregations) {
      const parsedAggregations = parseAggregations(rawData.aggregations);

      defaultMarketplaceState.aggregations = { ...defaultMarketplaceState.aggregations, ...parsedAggregations };
    }
  },
  isMobile: window.innerWidth <= 768,
});

export const marketplaceState: MarketPlaceState = defaultMarketplaceState;
