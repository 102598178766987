import React from 'react';
import ReactDOM from 'react-dom';
import MarketPlace from './MarketPlace';
import * as serviceWorker from './serviceWorker';

import './index.css';
import './stylesheets/position-styles.css';
import './stylesheets/width-styles.css';
import './stylesheets/display-styles.css';
import './stylesheets/height-styles.css';
import './stylesheets/padding-styles.css';
import './stylesheets/margin-styles.css';
import './stylesheets/flex-styles.css';
import './stylesheets/font-styles.css';
import './stylesheets/general-styles.css';

ReactDOM.render(<MarketPlace />, document.getElementById('root'));

serviceWorker.unregister();
