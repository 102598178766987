import React, { FC } from 'react';
import { useLocation } from 'react-router-dom';
import HeaderLogo from '../HeaderLogo/HeaderLogo';
import ApplyButton from '../ApplyButton/ApplyButton';

const Header: FC = () => {
  const { pathname } = useLocation();

  if (pathname.startsWith('/kereses')) return null;

  return (
    <header
      style={{ minHeight: 'max-content' }}
      className="padding-20px max-width-1500px margin-zero-tablet flex-column-mobile margin-horizontal-auto display-flex align-items-center width-100-percent"
    >
      <HeaderLogo />
      <div style={{ marginTop: 20 }} className="flex-1" />
      <p className="font-weight-600 font-size-15px margin-right-20px hide-on-mobile">Webshopod van?</p>
      <ApplyButton>Listázd ki a termékeid díjmentesen</ApplyButton>
    </header>
  );
};

export default Header;
