import axios from 'axios';

import { type MarketplaceStoresReturn } from '@rapidsearch/shared/lib/types/marketplace.types';
import { config } from '../config';
import { marketplaceName } from '../constants';

const currencyFormat = (num: number): string => num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ');
const updateTitle = (keyword: string): string => (document.title = `Keresés: ${keyword} | ${marketplaceName}`);
const addCloseButton = (): void => {
  const searchInput = document.querySelector('.search-input');
  if (searchInput) {
    searchInput.setAttribute('type', 'search');
  }
};

const imgConfig = {
  rootMargin: '0px 0px 50px 0px',
  threshold: 0,
};

const imageIntersectionObserver = new IntersectionObserver((entries, self) => {
  entries.forEach(({ target, isIntersecting }: any) => {
    if (isIntersecting) {
      target.src = target.dataset.src;
      self.unobserve(target);
    }
  });
}, imgConfig);

const lazyLoadImages = (): void => {
  const images = document.querySelectorAll('img[data-src]');

  if (!images.length) return;

  images.forEach((image) => imageIntersectionObserver.observe(image));
};

const setWithExpiry = (key: string, value: MarketplaceStoresReturn, ttl: number): void => {
  const now = new Date();

  // `item` is an object which contains the original value
  // as well as the time when it's supposed to expire
  const item = {
    value,
    expiry: now.getTime() + ttl,
  };
  localStorage.setItem(key, JSON.stringify(item));
};

const getWithExpiry = (key: string): MarketplaceStoresReturn | null => {
  const itemStr = localStorage.getItem(key);
  // if the item doesn't exist, return null
  if (!itemStr) return null;

  const item = JSON.parse(itemStr);
  const now = new Date();
  // compare the expiry time of the item with the current time
  if (now.getTime() > item.expiry) {
    // If the item is expired, delete the item from storage
    // and return null
    localStorage.removeItem(key);
    return null;
  }

  return item.value;
};

const fetchEnabledStores = async (): Promise<MarketplaceStoresReturn> => {
  const cachedStores = getWithExpiry('stores');
  const isNewVersion = !!cachedStores?.stores;
  if (cachedStores && isNewVersion) {
    // eslint-disable-next-line no-console
    console.log('Stores from cache');
    return cachedStores as MarketplaceStoresReturn;
  }
  // eslint-disable-next-line no-console
  console.log('Stores from db');
  const result = await axios(`${config.elasticSearch.url}/market/getEnabledStores/`);
  setWithExpiry('stores', result.data, 60 * 1000); // save store data to local storage for 1 min
  return result.data;
};

const toggleValueInArray = (value: string, array: Array<string>): Array<string> => {
  let newArray: Array<string> = JSON.parse(JSON.stringify(array));
  if (newArray.includes(value)) {
    newArray = newArray.filter((item) => item !== value);
  } else {
    newArray.push(value);
  }
  return newArray;
};

const parseAggregations = (rawAggregations: Record<string, any>): Record<string, unknown> => {
  const aggregations: { [key: string]: any } = {};

  Object.keys(rawAggregations).forEach((aggrName) => {
    let parsedData = [];
    if (aggrName === 'manufacturers') {
      const rawData = rawAggregations[aggrName].manufacturers_count.buckets;
      parsedData = rawData
        .filter((man: any) => man.key)
        .map((man: any) => {
          return { value: man.key, label: `${man.key} (${man.doc_count})` };
        });
    }
    if (aggrName === 'minimum' || aggrName === 'maximum') {
      parsedData = rawAggregations[aggrName].value;
    }
    aggregations[aggrName] = parsedData;
  });
  return aggregations;
};

const urlHasDomain = (url: string): boolean => {
  return /^https?:\/\/[^/?]+\//g.test(url);
};

export {
  addCloseButton,
  currencyFormat,
  fetchEnabledStores,
  lazyLoadImages,
  parseAggregations,
  toggleValueInArray,
  updateTitle,
  urlHasDomain,
};
