import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/logo.png';

const HeaderLogo: FC<{ imageClasses?: string }> = ({ imageClasses }) => (
  <Link className="margin-right-20px margin-zero-mobile" to="/">
    <img alt="app_logo" src={logo} className={`width-100-percent max-width-170px ${imageClasses || ''}`} />
  </Link>
);

export default HeaderLogo;
